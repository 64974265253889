<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import appConfig from "../../../app.config";

export default {
  page: {
    title: "Login",
    description: "",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      prevRoute: null
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  created: function () {
    if(this.getProfileId()) {
      this.$router.push({ path: '/account' });
    }
  },
  mounted: function () {
  },
  methods: {
    authGoole: function () {
      var redirect = '/';
      if(this.prevRoute.fullPath && this.prevRoute.fullPath !== '')
        redirect = this.prevRoute.fullPath;
      if(this.$route.query.redirectFrom && this.$route.query.redirectFrom !== '')
        redirect = this.$route.query.redirectFrom;
      console.log(redirect);
      document.location = process.env.VUE_APP_URL + 'api/auth/google?redirectUrl=' + redirect;
    },
    getProfileId: function() {
      return localStorage.getItem('user.id');
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
};
</script>
    
<template>
  <Layout>
    <PageHeader :title="title" :description="description" v-if="false" />

    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4 text-center">

            <div class="avatar-lg mx-auto mt-2">
              <div class="avatar-title bg-light text-primary display-3 rounded-circle">
                <i class="ri-login-box-line"></i>
              </div>
            </div>

            <div class="mt-4 pt-2">
              <h4>{{ $t('t-login-title') }}</h4>
              <p class="text-muted mx-4">{{ $t('t-login-description') }}</p>

              <h5>{{ $t('t-login-signin') }}</h5>
              <div class="mt-4">
                <b-button variant="primary" size="lg" class="btn-label" @click="authGoole">
                  <i class="ri-google-line label-icon align-middle fs-16 me-2"></i> Google
                </b-button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>